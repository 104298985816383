<template>
	<el-dialog class="dialog" title="查看下载" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<div class="table" ref="tableContainer" style="margin: 30px;">
				<el-table ref="usertable" :data="list" border :height="tableHeight"
					:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
					style="margin-bottom: 20px;">
					<el-table-column prop="name" align="center" label="附件名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column align="center" label="操作" width="180">
						<template slot-scope="scope">
							<el-button type="text" @click="download(scope.row)">
								下载
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				tableHeight: 500,
				list: [],
				title: "",
				loading: false,
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.list = [];
				this.get();
			},
			//获取数据
			get() {
				this.$get("/base-api/report_repair/get", {
					id: this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						for (let item of res.data.attachmentList) {
							let index = item.lastIndexOf("/");
							this.list.push({
								name: item.substring(index + 1, item.length),
								path: item
							})
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			download(row) {
				this.$get("/base-api/file/download", {
					path: row.path
				}).then((res) => {
					if (res.code == 1000) {
						var bstr = atob(res.data), //解析 base-64 编码的字符串
							n = bstr.length,
							u8arr = new Uint8Array(n); //创建初始化为0的，包含length个元素的无符号整型数组
						while (n--) {
							u8arr[n] = bstr.charCodeAt(n); //返回字符串第一个字符的 Unicode 编码
						}
						let index = row.path.lastIndexOf("/");
						let filename = row.path.substring(index + 1, row.path.length)
						const blob = new Blob([u8arr]); // 创建blob对象
						const elink = document.createElement("a"); // 创建的标签
						elink.download = filename;
						console.log(filename);
						elink.style.display = "none";
						elink.href = URL.createObjectURL(blob); // 创建url
						document.body.appendChild(elink); // 把 创建的标签追加到body里
						elink.click();
						URL.revokeObjectURL(elink.href); // 释放URL 对象
						document.body.removeChild(elink); // 移除标签
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
