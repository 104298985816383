<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="标题：" prop="title">
							<el-input v-model="formData.title" :disabled="dialogObj.type == 0"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注：">
							<el-input type="textarea" v-model="formData.remarks" :disabled="dialogObj.type == 0">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="文档附件：">
							<el-upload class="avatar-uploader" action="" :before-upload="BeforeUpload"
								:on-remove="handleRemove" :http-request="Upload" :file-list="fileList" accept=".pdf"
								:disabled="dialogObj.type == 0">
								<el-button size="small" type="primary" :disabled="dialogObj.type == 0">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过10MB</div>
							</el-upload>

						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()" v-if="dialogObj.type != 0">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {
					attachmentList: []
				},
				ents: [],
				newFile: new FormData(),
				fileList: [],
				attachmentObjList: [],
				rules: {
					title: [{
						required: true,
						message: '此项必填',
						trigger: 'blur'
					}],
					entId: [{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}],
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//获取企业列表
			getEnts() {
				this.$get("/backend-api/sys/ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开弹窗
			open() {
				this.fileList = [];
				this.attachmentObjList = [];
				this.formData = {
					attachmentList: []
				};
				this.get();
				this.getEnts();
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
			},
			//上传前校验
			BeforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				var filtype = file.name.substring(file.name.lastIndexOf(".") + 1);
				const extension = filtype === "pdf";
				if (!extension) {
					this.$message.error('上传文件只能是 PDF 格式!');
				} else if (!isLt2M) {
					this.$message.error('上传大小不能超过 10MB!');
				} else {
					if (file) {
						this.newFile = new FormData();
						this.newFile.append('file', file);
						return true;
					} else {
						return false;
					}
				}
				return false;
			},
			handleRemove(file, fileList) {
				for (let i in this.attachmentObjList) {
					if (this.attachmentObjList[i].name == file.name) {
						this.attachmentObjList.splice(i, 1);
					}
				}
				this.formData.attachmentList = [];
				for (let item of this.attachmentObjList) {
					this.formData.attachmentList.push(item.path);
				}
			},
			//手动上传
			Upload() {
				this.loading = true;
				this.$uploadWithName(this.newFile).then((res) => {
					if (res.code == 1000) {
						this.formData.attachmentList.push(res.data)
						this.setFileList();
						this.loading = false;
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.$message.error(error);
				});
			},
			setFileList() {
				this.attachmentObjList = [];
				for (let item of this.formData.attachmentList) {
					let index = item.lastIndexOf("/");
					this.attachmentObjList.push({
						name: item.substring(index + 1, item.length),
						path: item
					});
				}
			},
			//获取数据
			get() {
				if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
					if (this.dialogObj.type == 0) {
						this.title = "查看";
					} else {
						this.title = "编辑";
					}
					this.$get("/base-api/report_repair/get", {
						id: this.dialogObj.id
					}).then((res) => {
						if (res.code == 1000) {
							this.formData = res.data;
							this.setFileList();
							for (let item of this.formData.attachmentList) {
								let index = item.lastIndexOf("/");
								this.fileList.push({
									name: item.substring(index + 1, item.length)
								});
							}
						} else {
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.type == 2) {
					this.title = "上传诊断报告";
				}
			},
			//提交表单
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (!this.formData.attachmentList || this.formData.attachmentList.length == 0) {
							this.$message.error("请上传附件");
							return;
						}
						this.loading = true;
						if (this.dialogObj.type == 1) {
							this.$postData("/base-api/report_repair/save-or-update", this.formData).then((res) => {
								if (res.code == 1000) {
									this.loading = false;
									this.$message.success(res.msg);
									this.$parent.list();
									this.close();
								} else {
									this.loading = false;
									this.$message.error(res.msg);
								}
							});
						} else if (this.dialogObj.type == 2) {
							this.$postData("/base-api/report_repair/save-or-update", this.formData).then((res) => {
								if (res.code == 1000) {
									this.loading = false;
									this.$message.success(res.msg);
									this.$parent.list();
									this.close();
								} else {
									this.loading = false;
									this.$message.error(res.msg);
								}
							});
						}
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
